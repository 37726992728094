<template>
  <v-app-bar
    class="primary"
    fixed
    dense
    dark
  >
    <v-btn
      icon
      @click="$store.commit('toggleDrawer')"
    >        
      <v-icon dense>
        {{ !$store.state.isDrawerOpened ? 'mdi-menu' : 'mdi-close'}}
      </v-icon>
    </v-btn>
    <v-toolbar-title class="font-weight-bold pl-0">
      Scanner STI
    </v-toolbar-title>

    <!-- <v-spacer></v-spacer>

    <v-btn icon>
      <v-icon>mdi-bell</v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon>mdi-account-circle</v-icon>
    </v-btn>
    
    <v-btn icon @click="logout">
      <v-icon>mdi-logout-variant</v-icon>
    </v-btn> -->
  </v-app-bar>
</template>

<script>
  export default {
    name: "AppBar",
    methods: {
      logout() {
        this.$session.destroy()
        this.$cookies.remove('user_id')
        this.$cookies.remove('token')
        this.$router.push({name: 'login'})
      }
    },
    mounted() {
      this.$cookies.set('origin', window.location.origin)
    }
  }
</script>