import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/Layout'
import VueCookies from 'vue-cookies'

Vue.use(VueRouter)
Vue.use(VueCookies)

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          title: 'Home'
        },
        component: () => import('@/views/QrCodeReader')
      },
      {
        path: '/endpoint',
        name: 'endpoint',
        meta: {
          title: 'Definir Endpoint'
        },
        component: () => import('@/views/SetEndpoint')
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
