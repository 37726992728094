<template>
  <v-navigation-drawer id="default-drawer" v-model="$store.state.isDrawerOpened" absolute temporary>
    <v-list dense>
      <v-list-item class="my-3">
        <img src="@/assets/ticket.jpeg" width="100%" />
      </v-list-item>
      <v-list-item class="my-3" v-for="(item, idx) in items" :key="idx" :to="item.route">
        <v-list-item-icon class="mr-3">
          <v-icon dense>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Drawer",
  data: () => ({
    items: [
      {
        title: 'Ler Qr Code',
        icon: 'mdi-qrcode',
        route: { name: 'home' }
      },
      {
        title: 'Definir Endereço do Servidor',
        icon: 'mdi-file-edit',
        route: { name: 'endpoint' }
      },
    ]
  }),
}
</script>
