<template>
  <v-app style="background-color: lightgray">
    <AppBar />

    <Drawer />

    <v-main class="mt-15 mt-md-15">
      <v-container fluid>
        <v-card class="pa-5 px-10">
          <router-view />
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from './AppBar.vue'
import Drawer from './Drawer.vue'
export default {
    components: {
        AppBar,
        Drawer
    }
}
</script>